import { render, staticRenderFns } from "./LangMenu.vue?vue&type=template&id=3c7bad88&scoped=true"
import script from "./LangMenu.vue?vue&type=script&lang=js"
export * from "./LangMenu.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3c7bad88",
  null
  
)

export default component.exports