import store from '@/store'
import Api from '@/services/api'
import System from './System';

class User {

// Helper functions

    initials (string) {
        let names = string.split(' ');
        let initials = names[0].substring(0, 1).toUpperCase();

        if (names.length > 1) {
            initials += names[names.length - 1].substring(0, 1).toUpperCase();
        }
        
        return initials;
    }


// Set and get data functions

    setLogin (data) {
        store.commit('user/setProfile', data.profile);
        store.commit('user/setPlan', data.plan);
    }

    setLogout () {
        store.commit('user/resetState');
        localStorage.removeItem('to_route');
    }

    setSaved (data) {
        store.commit('user/setSaved', data);
    }

    setConfirmed () {
        store.commit('user/setConfirmed');
    }

    resetSaved () {
        store.commit('user/resetSaved');
    }

    setGroups (data) {
        store.commit('user/setGroups', data);
    }

    addGroup (data) {
        store.commit('user/addGroup', data);
    }

    setLinks (data) {
        store.commit('user/setLinks', data);
    }

    addLink (data) {
        store.commit('user/addLink', data);
    }

    updateLink (data) {
        store.commit('user/updateLink', data);
    }

    
// API functions

    login (data) {
        return Api.post('/user/login', data, false)
            .then(data => {
                this.setLogin(data);
                return data;
            });
    }

    loginSuper (data) {
        return Api.post('/user/login-super', data, false)
            .then(data => {
                this.setLogin(data);
                return data;
            });
    }

    register (data) {
        return Api.post('/user/register', data, false)
            .then(data => {
                this.setLogin(data);
                return data;
            });
    }

    remind (data) {
        return Api.post('/user/remind', data, false);
    }

    reset (data) {
        return Api.post('/user/reset', data, false);
    }

    resend () {
        return Api.post('/user/resend', {}, false);
    }

    chpass (data) {
        return Api.post('/user/chpass', data);
    }

    confirm (data) {
        return Api.post('/user/confirm', data, false)
            .then(data => {
                this.setConfirmed();
                return data;
            });
    }

    update (data) {
        return Api.post('/user/update', data)
            .then(data => {
                this.setLogin(data);
                return data;
            });
    }

    saveLocale (locale) {
        return Api.post('/user/saveLocale', {locale});
    }

    delete () {
        return Api.post('/user/delete')
            .then(data => {
                this.setLogin(data);
                return data;
            });
    }

    logout () {
        return Api.post('/user/logout')
            .finally(() => {
                this.setLogout();
                System.redirectToLogin(false);
            });
    }

}


export default new User();