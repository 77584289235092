import {
    mdiWeb,
    mdiPhone,
    mdiAt,
    mdiSendCircle,
    mdiWhatsapp,
    mdiCheck,
    mdiClose,
    mdiCalendarRemoveOutline,
    mdiCheckAll,
    mdiMap,
    mdiIpNetwork,
    mdiDevices,
    mdiWindowRestore,
    mdiTranslate,
    mdiWindowMaximize,
    mdiRobotOutline,
    mdiHelpCircleOutline,
    mdiMonitor,
    mdiCellphone,
    mdiCellphoneBasic,
    mdiTablet,
    mdiTelevision,
    mdiGamepadSquareOutline,
    mdiWatchVariant,
    mdiKey,
    mdiClockPlusOutline,
    mdiClockRemoveOutline,
    mdiCreditCardOffOutline,
    mdiTeddyBear,
    mdiTools,
    mdiInstagram,
    mdiLinkedin,
    mdiYoutube,
    mdiFacebook,
    mdiCloseOctagon,
} from '@mdi/js';

import System from '@/classes/System';

const USER_ROLE = {
    SUPER: 1,
    USER: 2,
}

const USER_STATUS = {
    OK: 1,
    DISABLED: 2,
    DELETED: 3,
}

const GROUP_STATUS = {
    ENABLED: 1,
    DISABLED: 2,
}

const GROUP_STATUSES = {
    0: {icon: mdiCheckAll,              iconColor: 'grey'},
    1: {icon: mdiCheck,                 iconColor: 'success'},
    2: {icon: mdiClose,                 iconColor: 'error'},
};

const LINK_STATUS = {
    ON: 1,
    OFF: 2,
    EXPIRED: 3,
    INACTIVE: 4,
    BLOCKED: 5,
}

const LINK_STATUSES = {
    0: {icon: mdiCheckAll,              iconColor: 'grey'},
    1: {icon: mdiCheck,                 iconColor: 'success'},
    2: {icon: mdiClose,                 iconColor: 'error'},
    3: {icon: mdiCalendarRemoveOutline, iconColor: 'error'},
    4: {icon: mdiCalendarRemoveOutline, iconColor: 'grey'},
    5: {icon: mdiCloseOctagon, iconColor: 'error'},
};

const LINK_TYPES = {
    all: {
        icon: mdiCheckAll,
        iconColor: 'grey',
    },
    web: {
        icon: mdiWeb,
        iconColor: '#1E88E5',
        rules: [
            (v) => !!v || System.lang('val.required'),
            (v) => /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i.test(v) || System.lang('val.url'),
        ],
    },
    phone: {
        icon: mdiPhone,
        iconColor: '#4CAF50',
        rules: [
            (v) => !!v || System.lang('val.required'),
            (v) => /^(?:\+\d{1,3})?\d{5,}$/.test(v) || System.lang('val.phone'),
        ],
    },
    email: {
        icon: mdiAt,
        iconColor: '#E53935',
        rules: [
            (v) => !!v || System.lang('val.required'),
            (v) => /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(v) || System.lang('val.email'),
        ],
    },
    telegram: {
        icon: mdiSendCircle,
        iconColor: '#229ED9',
        rules: [
            (v) => !!v || System.lang('val.required'),
            (v) => /^[a-zA-Z0-9_]{1,32}$/.test(v) || System.lang('val.telegram'),
        ],
    },
    whatsapp: {
        icon: mdiWhatsapp,
        iconColor: '#25D366',
        rules: [
            (v) => !!v || System.lang('val.required'),
            (v) => /^\+?[0-9]*$/.test(v) || System.lang('val.whatsapp'),
        ],
    },
    instagram: {
        icon: mdiInstagram,
        iconColor: '#E1306C',
        rules: [
            (v) => !!v || System.lang('val.required'),
            (v) => /^[a-zA-Z0-9_.]{1,30}$/.test(v) || System.lang('val.instagram'),
        ],
    },
    linkedin: {
        icon: mdiLinkedin,
        iconColor: '#0077B5',
        rules: [
            (v) => !!v || System.lang('val.required'),
            (v) => /^[a-zA-Z0-9_-]*$/.test(v) || System.lang('val.linkedin'),
        ],
    },
    youtube: {
        icon: mdiYoutube,
        iconColor: '#FF0000',
        rules: [
            (v) => !!v || System.lang('val.required'),
            (v) => /^[a-zA-Z0-9@]*$/.test(v) || System.lang('val.youtube'),
        ],
    },
    facebook: {
        icon: mdiFacebook,
        iconColor: '#1877F2',
        rules: [
            (v) => !!v || System.lang('val.required'),
            (v) => /^[a-zA-Z0-9.]*$/.test(v) || System.lang('val.facebook'),
        ],
    },
};

const FILTER_TYPES = {
    source:     {type: 1, icon: mdiWeb,       iconColor: '#1E88E5'},
    country:    {type: 2, icon: mdiMap,       iconColor: 'orange'},
    ip:         {type: 3, icon: mdiIpNetwork, iconColor: 'light-blue'},
    device:     {type: 4, icon: mdiDevices,   iconColor: 'teal'},
};

const FILTER_ACTION = {
    NONE: 0,
    ALLOW: 1,
    BLOCK: 2,
};

const RULE_TYPES = {
    lang:    {icon: mdiTranslate,      iconColor: '#1E88E5'},
    country: {icon: mdiMap,            iconColor: 'orange'},
    device:  {icon: mdiDevices,        iconColor: 'teal'},
    os:      {icon: mdiWindowRestore,  iconColor: 'light-blue'},
    browser: {icon: mdiWindowMaximize, iconColor: 'light-green'},
};

const STATS_PRESET = {
    TODAY: 1,
    YESTERDAY: 2,
    DATE: 3,
    THIS_MONTH: 4,
    PREV_MONTH: 5,
    MONTH: 6,
    DATES: 7,
};

const STATS_GRANULA = {
    HOUR: 1,
    DAY: 2,
};

const LOGS_PRESET =  {
    TODAY: 1,
    YESTERDAY: 2,
    DATE: 3,
};

const CLICK_RESULTS = {
    0:  {icon: mdiCheckAll, iconColor: 'grey'},
    1:  {icon: mdiCheck,    iconColor: 'success'},
    2:  {icon: mdiKey,      iconColor: 'error'},
    3:  {icon: mdiKey,      iconColor: 'error'},
    11: {icon: mdiClose,    iconColor: 'error'},
    12: {icon: mdiClose,    iconColor: 'error'},
    13: {icon: mdiClose,    iconColor: 'error'},
    14: {icon: mdiClose,    iconColor: 'error'},
};

const DEVICE_TYPES = {
    0:  {name: 'Unknown',        icon: mdiHelpCircleOutline,    iconColor: 'grey'},
    1:  {name: 'Search bots',    icon: mdiRobotOutline,         iconColor: 'grey'},
    2:  {name: 'Computers',      icon: mdiMonitor,              iconColor: '#8C9EFF'},
    3:  {name: 'Smartphones',    icon: mdiCellphone,            iconColor: '#00C853'},
    4:  {name: 'Tablets',        icon: mdiTablet,               iconColor: '#00C853'},
    5:  {name: 'Feature phones', icon: mdiCellphoneBasic,       iconColor: '#00C853'},
    6:  {name: 'Consoles',       icon: mdiGamepadSquareOutline, iconColor: 'grey'},
    7:  {name: 'TV',             icon: mdiTelevision,           iconColor: 'grey'},
    8:  {name: 'Car browsers',   icon: mdiDevices,              iconColor: 'grey'},
    9:  {name: 'Smart displays', icon: mdiTelevision,           iconColor: 'grey'},
    10: {name: 'Cameras',        icon: mdiDevices,              iconColor: 'grey'},
    11: {name: 'Media players',  icon: mdiDevices,              iconColor: 'grey'},
    12: {name: 'Phablets',       icon: mdiCellphone,            iconColor: '#00C853'},
    13: {name: 'Smart speakers', icon: mdiDevices,              iconColor: 'grey'},
    14: {name: 'Wearables',      icon: mdiWatchVariant,         iconColor: 'grey'},
    15: {name: 'Peripherals',    icon: mdiDevices,              iconColor: 'grey'},
};

const APIKEY_STATUS = {
    ON: 1,
    OFF: 2,
    EXPIRED: 3,
}

const APIKEY_STATUSES = {
    0: {icon: mdiCheckAll,              iconColor: 'grey'},
    1: {icon: mdiCheck,                 iconColor: 'success'},
    2: {icon: mdiClose,                 iconColor: 'error'},
    3: {icon: mdiCalendarRemoveOutline, iconColor: 'error'},
};

const DOMAIN_STATUS = {
    ON: 1,
    OFF: 2,
    ADDING: 3,
    DELETING: 4,
}

const DOMAIN_STATUSES = {
    0: {icon: mdiCheckAll,           iconColor: 'grey'},
    1: {icon: mdiCheck,              iconColor: 'success'},
    2: {icon: mdiClose,              iconColor: 'error'},
    3: {icon: mdiClockPlusOutline,   iconColor: 'orange'},
    4: {icon: mdiClockRemoveOutline, iconColor: 'error'},
};

// const PLANS = {
//     free:  {icon: mdiCreditCardOffOutline, color: 'grey',    style: 'grey--text'},
//     basic: {icon: mdiTeddyBear,      color: 'success', style: 'success--text'},
//     pro:   {icon: mdiTools,          color: 'blue',    style: 'blue--text'},
// };

const PLANS = {
    free:   {icon: mdiCreditCardOffOutline, color: 'grey',    style: 'grey--text'},
    basic:  {icon: mdiTeddyBear,            color: '#F57C00', style: 'orange--text text--darken-2'},
    pro:    {icon: mdiTools,                color: '#00B0FF', style: 'light-blue--text text--accent-3'},
    system: {icon: mdiTools,                color: '#D32F2F', style: 'red--text text--darken-2'},
};


export {
    USER_ROLE, 
    USER_STATUS,
    GROUP_STATUS, 
    GROUP_STATUSES, 
    LINK_STATUS, 
    LINK_STATUSES, 
    LINK_TYPES, 
    FILTER_TYPES, 
    FILTER_ACTION, 
    RULE_TYPES, 
    STATS_PRESET, 
    STATS_GRANULA, 
    LOGS_PRESET, 
    DEVICE_TYPES, 
    CLICK_RESULTS,
    APIKEY_STATUS,
    APIKEY_STATUSES,
    DOMAIN_STATUS,
    DOMAIN_STATUSES,
    PLANS,
};

