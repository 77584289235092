<template>
<div>
    <v-progress-linear indeterminate rounded class="my-2" v-if="loadingLink"></v-progress-linear>

    <!-- Settings -->
    <v-sheet width="100%" elevation="1" rounded class="pa-3 pa-sm-5" v-if="link && linkTypes.length && linkGroups.length">
        <HeaderPanel
            :title="System.lang('link.link')"
            :icon="mdiLink"
            color="success"
            class="mb-6"
        >
            <IconButton
                :icon="mdiArrowLeft"
                :hint="System.lang('link.backHint')"
                :fn="() => {System.redirectTo('links')}"
            />
            <IconButton
                :icon="mdiFinance"
                :hint="System.lang('links.linksTable.statsHint')"
                :fn="() => {System.redirectTo('stats', {params: {id: linkID}})}"
            />
            <IconButton
                :icon="mdiTextBoxSearchOutline"
                :hint="System.lang('links.linksTable.logHint')"
                :fn="() => {System.redirectTo('logs', {params: {id: linkID}})}"
            />
            <IconButton
                :icon="mdiRefresh"
                :hint="System.lang('buttons.refresh')"
                :fn="init"
            />
        </HeaderPanel>
        <LinkInfoBar :link="link" />
        <div class="mt-3 pl-4">
            <v-icon left color="orange" size="30">{{mdiArrowDownThick}}</v-icon>
        </div>
        <div v-if="deactivated" class="mt-3">
            <v-alert
                dense
                type="warning"
            >
                {{System.lang('link.deactivated')}}
            </v-alert>
        </div>
        <div v-else-if="blocked" class="mt-3">
            <v-alert
                dense
                type="error"
            >
                {{System.lang('link.blocked')}}
            </v-alert>
        </div>
        <ActionForm
            v-else
            :fn="updateLink"
            @errors="linkForm.errors = $event"
        >
            <v-row dense>
                <v-col cols="3" sm="4" lg="3">
                    <v-select
                        v-model="link.link_type_id"
                        item-value="id"
                        :items="linkTypes"
                        :label="System.lang('link.linkForm.type')"
                        @change="() => {$refs.linkdata.resetValidation(); $refs.linkdata.focus()}"
                    >
                        <template v-slot:item="{ item }">
                            <v-icon left :color="item.iconColor">{{item.icon}}</v-icon>
                            {{System.lang(`links.types.${item.code}.name`)}}
                        </template>

                        <template v-slot:selection="{ item }">
                            <v-icon :color="item.iconColor" left>{{item.icon}}</v-icon>
                            <span v-if="!System.isMobile()">{{System.lang(`links.types.${item.code}.name`)}}</span>
                        </template>
                    </v-select>
                </v-col>
                <v-col cols="9" sm="8" lg="7">
                    <v-text-field
                        counter="500"
                        ref="linkdata"
                        clearable
                        v-model="link.data"
                        :label="System.lang(`links.types.${typecode(link.link_type_id)}.dataName`)"
                        :hint="System.lang(`links.types.${typecode(link.link_type_id)}.dataHint`)"
                        :rules="Links.type(link.link_type_id).rules"
                        :error-messages="linkForm.errors.data"
                        @click="() => {linkForm.resetError('data')}"
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" sm="8" lg="7">
                    <v-text-field
                        counter="100"
                        clearable
                        v-model="link.descr"
                        :label="System.lang('link.linkForm.descr')"
                        :hint="System.lang('link.linkForm.descrHint')"
                        :error-messages="linkForm.errors.descr"
                        :rules="linkForm.rules.descr"
                        :prepend-icon="mdiTextBoxEditOutline"
                        @click="() => {linkForm.resetError('descr')}"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4" lg="3">
                    <v-select
                        v-model="link.link_group_id"
                        item-value="id"
                        item-text="name"
                        :items="linkGroups"
                        :prepend-icon="mdiFolderOutline"
                        :label="System.lang('link.linkForm.group')"
                        :error-messages="linkForm.errors.link_group_id"
                    >
                        <template v-slot:selection="{ item }">
                            {{groupName(item)}}
                        </template>
                        <template v-slot:item="{ item }">
                            {{groupName(item)}}
                        </template>
                    </v-select>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="9" sm="3">
                    <v-select
                        :disabled="link.status == LINK_STATUS.EXPIRED"
                        :items="linkStatusItems"
                        :label="System.lang('link.linkForm.status')"
                        v-model="link.status"
                        :rules="linkForm.rules.status"
                        :error-messages="linkForm.errors.status"
                    >
                        <template v-slot:item="{ item }">
                            <v-icon left :color="item.iconColor">{{item.icon}}</v-icon>
                            {{item.text}}
                        </template>

                        <template v-slot:prepend>
                            <v-icon :color="LINK_STATUSES[link.status].iconColor">{{LINK_STATUSES[link.status].icon}}</v-icon>
                        </template>
                    </v-select>
                </v-col>
                <v-col cols="12" sm="5" lg="4">
                    <v-menu
                        v-model="menuLinkExpires"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="link.valid_till"
                                :label="System.lang('link.linkForm.expiry')"
                                :hint="System.lang('link.linkForm.expiryHint')"
                                :prepend-icon="mdiCalendarRemoveOutline"
                                clearable
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                @click="() => {linkForm.resetError('expiry')}"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="link.valid_till"
                            first-day-of-week="1"
                            :min="currentDate"
                            no-title
                            scrollable
                        >
                            <v-btn
                                text
                                color="primary"
                                @click="menuLinkExpires = false"
                            >
                                {{System.lang('buttons.ok')}}
                            </v-btn>
                            <v-spacer></v-spacer>
                            <v-btn
                                text
                                color="success"
                                @click="link.valid_till = ''; menuLinkExpires = false"
                            >
                                {{System.lang('link.buttons.unsetDate')}}
                            </v-btn>
                        </v-date-picker>
                    </v-menu>
                </v-col>
                <v-col cols="12" sm="4" lg="3">
                    <v-text-field
                        counter="25"
                        clearable
                        v-model="link.password"
                        :prepend-icon="mdiKeyOutline"
                        :label="System.lang('link.linkForm.password')"
                        :hint="System.lang('link.linkForm.passwordHint')"
                        :error-messages="linkForm.errors.password"
                        :rules="linkForm.rules.password"
                        @click="() => {linkForm.resetError('password')}"
                    ></v-text-field>
                </v-col>
            </v-row>
        </ActionForm>
    </v-sheet>


    <!-- Rules -->
    <v-sheet width="100%" elevation="1" rounded class="pa-3 pa-sm-5 mt-5" v-if="link">
        <HeaderPanel
            :title="System.lang('rules.rules')"
            :icon="mdiArrowDecisionOutline"
            color="success"
            class="mb-6"
        >
        </HeaderPanel>
        <LinkRules :link="link" />
    </v-sheet>


    <!-- Filters -->
    <v-sheet width="100%" elevation="1" rounded class="pa-3 pa-sm-5 mt-5" v-if="link">
        <HeaderPanel
            :title="System.lang('link.filters')"
            :icon="mdiTrafficLight"
            color="success"
            class="mb-6"
        >
        </HeaderPanel>
        <v-row>
            <v-col cols="12" sm="5">
                <v-select
                    v-model="selectedFilter"
                    :items="filterTypes"
                    item-value="type"
                    item-text="type"
                    return-object
                    :label="System.lang('link.filterType')"
                    :hint="System.lang(`filters.types.${selectedFilter.code}.descr`)"
                    persistent-hint
                    @change="() => {}"
                >
                    <template v-slot:prepend>
                        <v-icon left :color="selectedFilter.iconColor">{{selectedFilter.icon}}</v-icon>
                    </template>

                    <template v-slot:selection="{ item }">
                        <!-- <v-icon left :color="item.iconColor">{{item.icon}}</v-icon> -->
                        {{System.lang(`filters.types.${item.code}.name`)}}
                    </template>

                    <template v-slot:item="{ item, attrs, on }">
                        <v-list-item v-on="on" v-bind="attrs">
                            <v-list-item-icon class="mr-3">
                                <v-icon left :color="item.iconColor">{{item.icon}}</v-icon>
                            </v-list-item-icon>                                
                            <v-list-item-content>
                                <v-list-item-title>{{System.lang(`filters.types.${item.code}.name`) || ''}}</v-list-item-title>
                                <v-list-item-subtitle>{{System.lang(`filters.types.${item.code}.descr`) || ''}}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </template>
                </v-select>
            </v-col>
        </v-row>
        <div class="mt-5" style="min-height: 180px;">
            <FilterSource :link="link" :filterType="selectedFilter" v-if="selectedFilter.code == 'source'" />
            <FilterCountry :link="link" :filterType="selectedFilter" v-if="selectedFilter.code == 'country'" />
            <FilterIP :link="link" :filterType="selectedFilter" v-if="selectedFilter.code == 'ip'" />
            <FilterDevice :link="link" :filterType="selectedFilter" v-if="selectedFilter.code == 'device'" />
        </div>
    </v-sheet>


    <!-- Delete -->
    <v-sheet width="100%" elevation="1" rounded class="pa-3 pa-sm-5 mt-5" v-if="link">
        <HeaderPanel
            :title="System.lang('link.delete')"
            :hint="System.lang('link.deleteHint')"
            :icon="mdiDeleteForever"
            color="error"
            class="mb-6"
        >
        </HeaderPanel>
        <DeleteActionButton 
            :ok="System.lang('link.messages.LINK_DELETED')"
            :confirm="System.lang('link.confirmDelete')"
            :confirmHint="System.lang('link.confirmDeleteHint')"
            :fn="deleteLink"
        />
    </v-sheet>

</div>
</template>


<script>
import {
    mdiLink,
    mdiLinkPlus,
    mdiRefresh,
    mdiCogOutline,
    mdiCheck,
    mdiClose,
    mdiMagnify,
    mdiArrowLeft,
    mdiWeb,
    mdiContentCopy,
    mdiArrowDownThick,
    mdiCalendarRemoveOutline,
    mdiTrafficLight,
    mdiFolderOutline,
    mdiTextBoxEditOutline,
    mdiKeyOutline,
    mdiArrowDecisionOutline,
    mdiDeleteForever,
    mdiFinance,
    mdiTextBoxSearchOutline,
} from '@mdi/js';

import System from '@/classes/System';
import User from '@/classes/User';
import Interface from '@/classes/Interface'
import Dates from '@/classes/Dates'
import Links from '@/classes/Links'
import {Table, Dialog, Form} from '@/classes/Elements'
import {LINK_TYPES, LINK_STATUSES, LINK_STATUS} from '@/constants/system'
import Api from '@/services/api'

import HeaderPanel from '@/components/system/HeaderPanel'
import IconButton from '@/components/system/IconButton'
import ActionForm from '@/components/forms/ActionForm'
import FilterSource from '@/components/filters/FilterSource'
import FilterCountry from '@/components/filters/FilterCountry'
import FilterIP from '@/components/filters/FilterIP'
import FilterDevice from '@/components/filters/FilterDevice'
import LinkRules from '@/components/parts/LinkRules'
import DeleteActionButton from '@/components/parts/DeleteActionButton'
import LinkInfoBar from '@/components/parts/LinkInfoBar'

export default {
    name: 'LinkView',
    components: {
        HeaderPanel,
        IconButton,
        ActionForm,
        FilterSource,
        FilterCountry,
        FilterIP,
        FilterDevice,
        LinkRules,
        DeleteActionButton,
        LinkInfoBar,
    },
    data () {
        return {
            // Icons
            mdiLink,
            mdiLinkPlus,
            mdiRefresh,
            mdiCogOutline,
            mdiCheck,
            mdiClose,
            mdiMagnify,
            mdiArrowLeft,
            mdiWeb,
            mdiContentCopy,
            mdiArrowDownThick,
            mdiCalendarRemoveOutline,
            mdiTrafficLight,
            mdiFolderOutline,
            mdiTextBoxEditOutline,
            mdiKeyOutline,
            mdiArrowDecisionOutline,
            mdiDeleteForever,
            mdiFinance,
            mdiTextBoxSearchOutline,
            // Globals
            System,
            Links,
            LINK_TYPES,
            LINK_STATUSES,
            LINK_STATUS,
            // Internal
            loadingLink: false,
            menuLinkExpires: false,
            // Data
            linkGroups: [],
            linkTypes: [],
            // filterTypes: [],
            link: undefined,
            selectedFilter: undefined,
            // Data structures
            // Forms
            linkForm: new Form({
                link_type_id: [],
                link_group_id: [],
                data: [],
                status: [],
                valid_till: [],
                descr: [
                    (v) => /^.{0,100}$/.test(v) || System.lang('val.shorter'),
                ],
                password: [
                    (v) => /^[\p{L}\p{N}]*$/u.test(v) || System.lang('val.alphanumeric'),
                    (v) => /^.{0,25}$/.test(v) || System.lang('val.shorter'),
                ],
            }),
            // Tables
            linksTable: new Table([
            ], 'links-table', this.fetchLink),
            // Dialogs
            newLinkDialog: new Dialog(() => {this.linkForm.reset()}),
        }
    },
    computed: {
        linkID () {
            return Number(this.$route.params.id);
        },
        linkStatusItems () {
            if (this.link.status == LINK_STATUS.EXPIRED) {
                return [
                    {value: 3, text: System.lang('links.status.3'), icon: LINK_STATUSES[3].icon, iconColor: LINK_STATUSES[3].iconColor},
                ]
            } else {
                return [
                    {value: 1, text: System.lang('links.status.1'), icon: LINK_STATUSES[1].icon, iconColor: LINK_STATUSES[1].iconColor},
                    {value: 2, text: System.lang('links.status.2'), icon: LINK_STATUSES[2].icon, iconColor: LINK_STATUSES[2].iconColor},
                ]
            }
        },
        currentDate () {
            return Dates.today();
        },
        deactivated () {
            return this.link?.status == LINK_STATUS.INACTIVE;
        },
        blocked () {
            return this.link?.status == LINK_STATUS.BLOCKED;
        },
        filterTypes () {
            return Links.getFilterTypes();
        },
    },
    methods: {
        init () {
            System.setTitle('titles.link');
            this.fetchLink();
            this.fetchLinkTypes();
            this.fetchGroups();
            this.selectedFilter = this.filterTypes[0];
        },
        // Data fetch functions
        async fetchLink () {
            this.link = undefined;
            this.loadingLink = true;

            return Api.get(`/links/${this.linkID}`)
                .then(data => {
                    this.link = data;
                    this.linkForm.resetErrors();
                    return data;
                })
                .catch(error => {
                    Interface.popupError(System.lang('messages.REQUEST_FAILED'), error.message);
                })
                .finally(() => {
                    this.loadingLink = false;
                });
        },
        async fetchLinkTypes () {
            return Links.getLinkTypes()
                .then(data => {
                    this.linkTypes = data;
                    return data;
                })
                .catch(error => {
                    Interface.popupError(System.lang('messages.REQUEST_FAILED'), error.message);
                });
        },
        async fetchGroups () {
            return Api.get(`/groups`)
                .then(data => {
                    this.linkGroups = data;
                    return data;
                })
                .catch(error => {
                    Interface.popupError(System.lang('messages.REQUEST_FAILED'), error.message);
                });
        },
        // Data update functions
        async updateLink () {
            return Api.patch(`/links/${this.linkID}`, {...this.link})
                .then(data => {
                    this.link = data;
                    this.linkForm.resetErrors();
                    User.updateLink(data);
                    return data;
                });
        },
        async deleteLink () {
            return Api.delete(`/links/${this.linkID}`, {})
                .then(data => {
                    System.redirectTo('links');
                    return data;
                });
        },
        // Helper functions
        typecode (id) {
            return Links.typecode(id);
        },
        groupName (group) {
            if (group.is_main) return System.lang('groups.default');
            return group.name;
        },
    },
    mounted () {
        this.init();
    },
    watch: {
        linkID () {
            this.init();
        },
    },
}
</script>


<style scoped>
</style>
