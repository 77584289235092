import {en} from 'vuetify/lib/locale'

export default {
    ...en,

    // Errors
    errors: {
        'DEFAULT': 'Error code {0}',
        '0': 'Network or connection error',
        '403': 'Access denied',
        '404': 'Requested resource not found',
        '422': 'Form contains errors',
        '500': 'Backend server error',
        'UNKNOWN': 'Unknown error',
        'NETWORK': 'Network or connection error',
        'BACKEND_FAILED': 'Backend connection failed',
        'LOGIN_FAILED': 'Login failed',
    },

    // Global messages
    messages: {
        'REQUEST_FAILED': 'Request Failed',
        'SAVE_OK': 'Saved',
        'SAVE_ERROR': 'Save Failed',
        'SESSION_EXPIRED': 'Your session expied. Please login again.',
        'CLIPBOARD_COPIED': 'Copied to clipboard',
        'CLIPBOARD_FAILED': 'Failed to copy',
        'CLIPBOARD_NOT_AVAIL': 'Clipboard not available',
        'PLAN_LIMIT': 'You have reached the limit of your subscription plan',
        'PLAN_ALLOWED': 'Feature not available in your subscription plan',
    },

    // Global buttons
    buttons: {
        ok: 'OK',
        cancel: 'Cancel',
        close: 'Close',
        delete: 'Delete',
        copy: {
            label: 'Copy',
            hint: 'Copy to clipboard',
        },
        confirm: 'Confirm',
        refresh: 'Refresh',
        add: 'Add',
        save: {
            label: 'Save',
            hint: 'Save changes',
        },
        help: {
            label: 'Help',
            hint: 'Show help',
        },
        config: {
            label: 'Configure',
            hint: 'Configure'
        },
        translate: {
            label: 'Translate',
            hint: 'Translations'
        },
        upgrade: 'Upgrade',
    },

    // Interface
    interface: {
        logout: 'Logout',
        info: 'Information',
        success: 'Success',
        warning: 'Warning',
        error: 'Error',
        rowsPerPage: 'Per page:',
        allItems: 'All',
    },

    // Page titles
    titles: {
        common: 'shorten-url',
        account: 'Account settings',
        api: 'API access',
        domains: 'My domains',
        group: 'Group settings',
        links: 'My links',
        link: 'Link settings',
        logs: 'Link logs',
        plans: 'Subscription settings',
        login: 'My account',
        register: 'Create new account',
        forgotPass: 'Password reset',
        stats: 'Link statistics',
        support: 'Support',
    },

    // Main menu
    menu: {
        urls: 'Short Links',
        myurls: 'My Links',
        domains: 'My Domains',
        settings: 'Settings',
        account: 'My Account',
        plans: 'My Subscription',
        apikeys: 'API access',
        help: 'Help',
        support: 'Support',
    },

    // Form validation messages
    val: {
        invalid: 'Invalid characters',
        required: 'Field required',
        shorter: 'Must be shorter',
        longer: 'Must be longer',
        less: 'Must be less',
        more: 'Must be more',
        numeric: 'Must be numeric',
        alphanumeric: 'Only letters and numbers',
        url: 'Must be a vaid URL',
        ip: 'Must be a vaid IP',
        domain: 'Must be a valid domain',
        domainpart: 'Must be a valid domain name or its part',
        phone: 'Must be a vaid phone number',
        email: 'Must be a vaid email address',
        linkkey: 'Only letters, numbers, - and _ symbols',
        passmatch: 'Passwords do not match',
        agree: 'You must agree with service terms',
        telegram: 'Must be a valid Telegram nickname',
        whatsapp: 'Must be a valid Whatsapp phone',
        instagram: 'Must be a valid Instagram nickname',
        linkedin: 'Must be a valid LinkedIn nickname',
        youtube: 'Must be a valid Youtube channel ID or handle',
        facebook: 'Must be a valid Facebook profile name',
    },

    // Login screen
    login: {
        username: {
            label: 'Email',
            hint: 'Enter your login email',
            errors: [
                'Enter your login email',
                'Must be a valid email',
            ],
        },
        password: {
            label: 'Password',
            hint: 'Enter your password',
            errors: [
                'Enter your password',
            ],
        },
        buttons: {
            login: "Login",
        },
        errors: [
            'Incorrect password',
            'Login Failed',
        ],
        remember: 'Remember Me',
        forgot: 'Forgot password?',
        register: 'Register new account',
        user: 'User',
    },

    // Register
    register: {
        regForm: {
            email: 'Email',
            emailHint: 'Email will be used for login',
            name: 'Your Name',
            nameHint: 'Tell us how to call you?',
            password: 'Password',
            passwordHint: 'Choose your password',
            password2: 'Password Confirmation',
            password2Hint: 'Confirm your password',
        },
        createNew: 'Create New Account',
        agreeTerms: 'Accept service terms and privacy policy',
        termsHint: 'By using this service you agree with <a href="https://shorten-url.com/terms" target="_blank">Terms</a> and <a href="https://shorten-url.com/policy" target="_blank">Privacy</a> policy.',
        loginExisting: 'Login with existing account',
        messages: {
            CAPTCHA_FAILED: 'Captcha validation failed',
            REG_SUCCESS: 'Check your email to complete the registration...',
            REG_FAILED: 'Registration failed',
            REG_OK: 'Check your email to complete the registration...',
        },
    },

    // Remind password
    remind: {
        remindForm: {
            email: 'Email',
            emailHint: 'Your login email',
        },
        messages: {
            SEND_OK: 'Please check your mailbox for password reset email',
            SEND_FAILED: 'Failed to send',
        },
        sendRemind: 'Send reset link',
        sendRemindHint: 'We will send you password reset link and instructions if email entered is correct.',
        loginExisting: 'Back to login',
    },

    // Reset password
    reset: {
        resetForm: {
            password: 'Password',
            passwordHint: 'Choose your password',
            password2: 'Password Confirmation',
            password2Hint: 'Confirm your password',
        },
        messages: {
            SET_OK: 'Your password is changed, use it to login',
            SET_FAILED: 'Failed to set new password',
        },
        setPass: 'Set new password',
        newPassHint: 'Please set your new password',
        loginExisting: 'Back to login',
    },

    // Confirm new user email
    confirm: {
        messages: {
            CONFIRM_OK: 'Your email was successfully confirmed.',
            CONFIRM_FAILED: 'Failed to confirm email',
        },
    },

    // Groups
    groups: {
        groups: 'Groups',
        default: 'Default Group',
        disabled: 'Group is disabled. All links in this group will be inactive until you enable the group.',
        newGroupHint: 'Create new group',
        groupSettingsHint: 'Group settings',
        newGroupDialog: 'New group',
        newGroupDialogHint: 'Create new link groups for better management of your links. Grouping gives you a better control over a set of the links together.',
        messages: {
            GROUP_ADDED: 'Group created',
        },
        newGroupForm: {
            name: 'Group name',
            nameHint: 'Give a group any name',
        },
    },

    // Group screen
    group: {
        backHint: 'Back to links',
        group: 'Group Settings',
        groupHint: 'Manage group settings, change group name and status. If you disable the group then all links in this group will be disabled too.',
        filters: 'Traffic Filters',
        filtersHint: 'Filter undesired visitors by country, IP address or what device and software visitor is using. Filters rely on data sent by visitor software and may not be accurate.',
        filterType: 'Group filter type:',
        messages: {
            GROUP_DELETED: 'Group deleted',
        },
        status: {
            0: 'All',
            1: 'Enabled',
            2: 'Disabled',
        },
        groupForm: {
            name: 'Group name',
            nameHint: 'Your group name',
            color: 'Color',
            status: 'Group status',
            disabledWarn: 'All links in this group will be inactive if you disable the group.',
        },
        delete: 'Delete',
        deleteHint: 'Delete this group, all links in this group and related statistics.',
        confirmDelete: 'Delete group and its links?',
        confirmDeleteHint: 'This will delete this group and all links in this group',
    },

    // Links screen
    links: {
        links: 'My Links',
        linksHint: 'Manage your short links in this group. Press buttons on the righ to view short link click statistics, raw request log, generate QR code for your link. Select links using checkbox on the left to delete and disable link or move it to another group.',
        newLinkDialog: 'New short link',
        newLinkDialogHint: 'Create new short link. Click advanced settings to set your link description, expiry, access password and domain.',
        selected: 'Selected',
        newLinkForm: {
            type: 'Type',
            advanced: 'Advanced settings',
            advancedHint: 'Add link description, setup access password, configure expiry date and more...',
            domain: 'Short URL domain',
            key: 'Short URL key (optional)',
            keyHint: 'Leave blank to get random key',
            expiry: 'Expiry date (optional)',
            expiryHint: 'Leave blank for never',
            descr: 'Description (optional)',
            descrHint: 'Add a short note to yourself',
            password: 'Access password (optional)',
            passwordHint: 'Protect link with password',
            yourNewLink: 'Your new short link:',
        },
        linksTable: {
            type: 'Type',
            url: 'Link URL',
            clicks: 'Clicks',
            status: 'Status',
            actions: 'Actions',
            qrHint: 'Create QR code',
            statsHint: 'View statistics',
            logHint: 'View log',
            search: 'Search',
            resetSearch: 'Clear search parameters',
            noData1: 'No links in this group...',
            noData2: 'Press "Create new..." button to create new short link.',
            noResults: 'No links match your search...',
            loading: 'Loading data...',
        },
        buttons: {
            unsetDate: "Never Expires",
            addmore: "Add More",
            move: {
                label: 'Move',
                hint: 'Move selected links to another group',
            },
            disable: {
                label: 'Disable',
                hint: 'Temporary disable selected links',
            },
            delete: {
                label: 'Delete',
                hint: 'Delete selected links',
            },
            new: {
                label: 'Create New...',
                hint: 'Create new short link',
            },
            search: {
                label: 'Search',
                hint: 'Search links',
            },
            enable: {
                label: 'Enable',
                hint: 'Enable selected links',
            },
        },
        messages: {
            LINK_ADDED: 'New short link created',
            LINKS_DELETED: 'Links deleted',
            LINKS_DISABLED: 'Links disabled',
            LINKS_ENABLED: 'Links enabled',
            LINKS_MOVED: 'Links moved',
        },
        status: {
            0: 'All',
            1: 'Enabled',
            2: 'Disabled',
            3: 'Expired',
            4: 'Inactive',
            5: 'Blocked',
        },
        types: {
            all: {
                name: 'All',
            },
            web: {
                name: 'Web page',
                descr: 'Link to any webpage',
                dataName: 'URL',
                dataHint: 'Enter page URL starting with http:// or https://',
            },
            phone: {
                name: 'Phone number',
                descr: 'Link to a phone number',
                dataName: 'Phone number',
                dataHint: 'Enter full phone number without spaces and dashes',
            },
            email: {
                name: 'Email address',
                descr: 'Link to an email address',
                dataName: 'Email',
                dataHint: 'Enter email address',
            },
            telegram: {
                name: 'Telegram',
                descr: 'Link to a Telegram profile',
                dataName: 'Telegram nickname',
                dataHint: 'Enter Telegram nickname',
            },
            whatsapp: {
                name: 'WhatsApp',
                descr: 'Link to a WhatsApp profile',
                dataName: 'Phone number',
                dataHint: 'Enter full WhatsApp phone number without spaces and dashes',
            },
            instagram: {
                name: 'Instagram',
                descr: 'Link to an Instagram profile',
                dataName: 'Instagram nickname',
                dataHint: 'Enter Instagram profile nickname',
            },
            linkedin: {
                name: 'LinkedIn',
                descr: 'Link to a LinkedIn profile',
                dataName: 'Profile nickname',
                dataHint: 'Enter LinkedIn profile nickname',
            },
            youtube: {
                name: 'Youtube',
                descr: 'Link to a Youtube channel',
                dataName: 'Channel ID or handle',
                dataHint: 'Enter Youtube channel ID or handle stating with @',
            },
            facebook: {
                name: 'Facebook',
                descr: 'Link to a Facebook profile',
                dataName: 'Profile name',
                dataHint: 'Enter Facebook profile name',
            },
        },
        confirmDelete: 'Delete selected links?',
        confirmDeleteHint: 'This will delete selected short links and all related statistics',
        confirmDisable: 'Disable selected links?',
        confirmDisableHint: 'This will disable selected short links without deleting them',
        confirmEnable: 'Enable selected links?',
        confirmEnableHint: 'This will enable selected short links',
        moveDialog: 'Move links',
        moveDialogHint: 'Move selected links to another group.',
        moveForm: {
            name: 'Group name',
            nameHint: 'Give a group any name',
            newGroup: 'Create new group...',
        },
    },

    // Link screen
    link: {
        backHint: 'Back to links',
        link: 'Link Settings',
        linkHint: 'Manage link data and settings.',
        filters: 'Traffic Filters',
        filtersHint: 'Filter undesired visitors by country, IP address or what device and software visitor is using. Filters rely on data sent by visitor software and may not be accurate.',
        filterType: 'Filter type:',
        deactivated: 'Link is deactivated',
        blocked: 'Link is blocked',
        linkForm: {
            created: 'Link created:',
            clicks: 'Total clicks:',
            clicks_ok: 'Total approved:',
            clicks_blocked: 'Total blocked:',
            type: 'Type:',
            descr: 'Description (optional)',
            descrHint: 'Add a short note to yourself',
            group: 'Group',
            status: 'Status',
            expiry: 'Expiry date (optional)',
            expiryHint: 'Leave blank for never',
            password: 'Access password (optional)',
            passwordHint: 'Protect link with password',
        },
        buttons: {
            unsetDate: "Never Expires",
        },
        messages: {
            LINK_DELETED: 'Short link deleted',
        },
        delete: 'Delete',
        deleteHint: 'Delete this link and all collected statistics.',
        confirmDelete: 'Delete short link?',
        confirmDeleteHint: 'This will delete your short link and all related statistics',
    },


    // Filters
    filters: {
        types: {
            all: {
                name: 'All',
            },
            source: {
                name: 'Traffic Source',
                descr: 'Block bots, direct traffic and links from websites',
                allow_bots: 'Allow search engine bots',
                allow_direct: 'Allow direct traffic',
                allow_sites: 'Allow clicks from other websites',
                filterSites: 'Filter referer websites:',
                addSite: 'Add website',
                wldescr: 'block all domains except listed below',
                bldescr: 'allow all domains except listed below',
                domains: {
                    0: '',
                    1: 'Allowed website domains:',
                    2: 'Blocked website domains:',
                },
                addDialog: {
                    title: 'Add website',
                    hint: 'Add new website domian to the list',
                    addButton: 'Add',
                    domain: 'Website domain',
                    helpText: 'Enter a website full domain name or any its part. For example: "google.com", "google", ".com" ... If you enter only a part of the domain then it will affect all domains that have this part. For example: ".com" will mean all domains in .com zone.',
                },
                noSelectedWarn: 'Add allowed websites to the list otherwise all requests will be blocked.',
            },
            country: {
                name: 'Countries',
                descr: 'Block selected countries',
                wldescr: 'block all countries except selected',
                bldescr: 'allow all countries except selected',
                countries: {
                    0: 'Countries',
                    1: 'Allowed countries:',
                    2: 'Blocked countries:',
                },
                noSelectedWarn: 'Add allowed countries to the list otherwise all requests will be blocked.',
            },
            ip: {
                name: 'IP Address',
                descr: 'Block selected IP address ranges',
                wldescr: 'block all IP addresses except listed below',
                bldescr: 'allow all IP addresses except listed below',
                addresses: {
                    0: '',
                    1: 'Allowed IP addresses:',
                    2: 'Blocked IP addresses:',
                },
                addIP: 'Add IP address',
                addDialog: {
                    title: 'Add IP Address',
                    hint: 'Add new IP address or range to the list',
                    addButton: 'Add',
                    single: 'Single IP address',
                    range: 'IP address range',
                    from: 'Range start',
                    to: 'Range end',
                    ip: 'IP address',
                    mustBeLarger: 'Must be larger than range start',
                },
                noSelectedWarn: 'Add allowed IP addresses to the list otherwise all requests will be blocked.',
            },
            device: {
                name: 'Device Type',
                descr: 'Block selected device types',
                allowed: 'Allowed devices:',
                allow_desktop: 'Desktops and notebooks',
                allow_mobile: 'Mobile devices',
                allow_tv: 'TV, car multimedia, consoles',
                allow_wear: 'Wearables and peripherals',
                allow_other: 'Other and unknown',
            },
            software: {
                name: 'Device Software',
                descr: 'Block operating systems and browsers',
            },
        },
        action: 'Action:',
        actions: {
            0: 'Do not filter',
            1: 'Whitelist',
            2: 'Blacklist',
        },
    },

    // Rules
    rules: {
        rules: 'Redirect Rules',
        rulesHint: 'Create alternate link destinations depending on visitor browser language, visitor country and browser software.',
        noRulesHint: 'Press "New rule" button to create your first rule...',
        buttons: {
            add: {
                label: 'New Rule',
                hint: 'Create new redirect rule',
            },
        },
        ruleDialog: {
            titleAdd: 'Add new rule',
            hintAdd: 'Add new rule to the list',
            titleEdit: 'Change rule',
            hintEdit: 'Change rule settings',
            priority: 'Priority',
        },
        ruleForm: {
            type: 'Type',
            priority: 'Rule priority: ',
            if: 'If...',
            select: 'Select:',
        },
        rulesTable: {
            priority: 'P-ty',
            if: 'If...',
            to: 'Redirect to',
            actions: 'Actions',
            never: 'Never',
        },
        confirmDel: 'Delete rule?',
        redirectto: 'Redirect to:',
        types: {
            all: {
                name: 'All',
            },
            lang: {
                name: 'Browser language',
            },
            country: {
                name: 'Visitor country',
            },
            device: {
                name: 'Traffic source',
            },
            os: {
                name: 'Operating system',
            },
            browser: {
                name: 'Browser',
            },
        },
        devices: {
            bot: 'Search engine bots',
            desktop: 'Desktops and notebooks',
            mobile: 'Mobile devices',
            tv: 'TV, car multimedia, consoles',
            wear: 'Wearables and peripherals',
        },

    },


    // Link stats screen
    stats: {
        backHint: 'Back to links',
        linkSettings: 'Link settings',
        linkLogs: 'Link raw logs',
        stats: 'Link Statistics',
        statsHint: 'View link click statistics. Select a period for which you wish to see the stats for by clicking presets below.',
        noStatsHint: 'Nothing for this period',
        statsFor: 'Show statistics for:',
        periods: {
            today: 'Today',
            yesterday: 'Yesterday',
            day: 'Date...',
            thisMonth: 'This month',
            prevMonth: 'Previous month',
            month: 'Month...',
            selectDates: 'Select dates...',
        },
        sections: {
            clicks: 'Clicks',
            clicksHint: 'See how many clicks you have, how many of those clicks were approved and blocked due to filers or password. See the traffic sources where your users are coming from.',
            sources: 'Traffic Sources:',
            // sourcesHint: 'This chart shows where your link visitors come from, links from other websites, qr code or directly typed address.',
            results: 'Approved / Blocked:',
            devices: 'Devices and Software',
            devicesHint: 'See what devices and software your visitors are using.',
            deviceTypes: 'Device Types:',
            os: 'Operating Systems:',
            browsers: 'Browsers:',
            clientTypes: 'Client Types:',
            searchBots: 'Search Bots:',
            clientLangs: 'Browser Languages:',
            geo: 'Geography',
            geoHint: 'Clicks geography. See which countries your users are coming from. This information might be inaccurate if VPN service is used.',
            showBots: 'Show search bots',
        },
        charts: {
            1: {
                clicks: 'Clicks:',
                clicks_success: 'Approved:',
                clicks_blocked: 'Blocked:',
                success: 'Approved',
                blocked: 'Blocked',
            },
            2: {
                success: 'approved',
                blocked: 'blocked',
            },
        },
    },


    // Logs screen
    logs: {
        backHint: 'Back to links',
        linkSettings: 'Link settings',
        linkStats: 'Link statistics',
        logs: 'Link Logs',
        logsHint: 'View link raw logs. Select a period for which you wish to see the logs for by clicking presets below.',
        logsFor: 'Show logs for:',
        logDialog: 'Full Log',
        periods: {
            today: 'Today',
            yesterday: 'Yesterday',
            day: 'Date...',
        },
        buttons: {
            search: {
                label: 'Search',
                hint: 'Search log entries',
            },
            view: {
                label: 'View',
                hint: 'View full log entry',
            },
        },
        logsTable: {
            when: 'When',
            ip: 'IP',
            country: 'Country',
            locale: 'Locale',
            source: 'Source',
            device: 'Device',
            os: 'OS',
            browser: 'Browser',
            result: 'Result',
            actions: 'Actions',
        },
        devices: {
            0: 'Unknown',
            1: 'Search bot',
            2: 'Computer/Notebook',
            3: 'Smartphone',
            4: 'Tablet',
            5: 'Feature phone',
            6: 'Console',
            7: 'TV',
            8: 'Car browser',
            9: 'Smart display',
            10: 'Camera',
            11: 'Media player',
            12: 'Phablet',
            13: 'Smart speaker',
            14: 'Wearable',
            15: 'Peripheral',
        },
        clients: {
            0: 'Unknown',
            1: 'Search bot',
            2: 'Browser',
            3: 'Reader',
            4: 'Librarie',
            5: 'Media player',
            6: 'Mobile app',
            7: 'Messenger',
        },
        results: {
            1: 'Approved',
            2: 'No password',
            3: 'Wrong password',
            11: 'Country filter',
            12: 'Traffic filter',
            13: 'Device filter',
            14: 'IP filter',
        },
        sources: {
            bot: 'Search bot',
            direct: 'Direct traffic',
            qr: 'QR code',
        },
        tables: {
            when: 'When:',
            source: 'Source:',
            destination: 'Redirected to:',
            blocked: 'Blocked: ',
            device: {
                label: 'Device',
                type: 'Device Type:',
                brand: 'Device Brand:',
                model: 'Device Model:',
            },
            os: {
                label: 'Operating System',
                name: 'Name:',
                version: 'Version:',
            },
            geo: {
                label: 'Geo IP',
                ip: 'IP address:',
                country: 'Country code:',
                coords: 'Coordinates:',
            },
            soft: {
                label: 'Software',
                clientType: 'Client type:',
                locale: 'Language locale:',
                browserName: 'Browser name:',
                browserVer: 'Browser version:',
                clientName: 'Client name:',
                clientVer: 'Client version:',
            },
            bot: {
                label: 'Search Bot',
                name: 'Bot name:',
            },
        },
    },


    // API keys screen
    apikeys: {
        apikeys: 'API Keys',
        apikeysHint: 'Manage your API keys. API key is required to access your account using API. API access allows you to manage your links and groups in programmatic way. Each API key can be restricted to read-only access too. Keep your keys in secret.',
        noKeys1: 'API keys are required to access your account via API.',
        noKeys2: 'Press "Create new..." button to create your first key...',
        neverExpires: 'Never expires',
        newKeyDialog: 'Create New Key',
        newKeyDialogHint: 'Create new API key.',
        showKeyDialog: 'View Key',
        showKeyDialogHint: 'Here you can view the API key and copy it to clipboard. Keep it in safe place.',
        confirmDel: 'Delete API key?',
        viewDoc: 'View API Documentation',
        status: {
            0: 'All',
            1: 'Enabled',
            2: 'Disabled',
            3: 'Expired',
        },
        buttons: {
            new: {
                label: 'Create new...',
                hint: 'Create new API key',
            },
            view: {
                label: 'View key',
                hint: 'View API key',
            },
        },
        messages: {
            KEY_ADDED: 'API key created',
            KEY_DELETED: 'API key deleted',
        },
        keysTable: {
            name: 'Key name',
            created: 'Created',
            permission: 'Permission',
            expires: 'Expires',
            status: 'Status',
            actions: 'Actions',
            fullPerm: 'Full',
            roPerm: 'Read only',
        },
        newKeyForm: {
            name: 'Key name',
            nameHint: 'Give new key a name',
            readonly: 'Read only permission',
            expiry: 'Expiry date',
            expiryHint: 'Leave blank for never',
        },
    },


    // Domains screen
    domains: {
        domains: 'My Domains',
        domainsHint: 'You can use your custom domains for your short links. With custom domain you get a full control over your link URLs and keys boosting up your brand marketing. You must be the owner of the custom domain you wish to add. Changes in nameserver configuration will be required to point your domain to our servers.',
        noDomains1: 'You can add your own custom domains for your short links. ',
        noDomains2: 'Custom domain will be available only to you and will provide exclusive control over short link keys. You will be able to create your short links as https://your_domain/your_key',
        noDomains3: 'You must be the owner of the domain you wish to use and the domain must not be used in any other websites. You will need to configure your domain to point to our nameservers to start.',
        noDomains5: 'Press "Add Domain" button to to start adding your custom domain...',
        newDomainDialog: 'Add Your Domain',
        newDomainDialogHint: 'Add your new custom domain.',
        confirmDel: 'Request domain and all its links deletion?',
        nsHint: 'In order to continue with new domain addition please configure following nameservers at your domain registar DNS:',
        status: {
            0: 'All',
            1: 'Enabled',
            2: 'Disabled',
            3: 'Processing',
            4: 'In deletion',
        },
        buttons: {
            new: {
                label: 'Add Domain',
                hint: 'Add your custom domain',
            },
        },
        messages: {
            DOMAIN_ADDED: 'Domain added',
            DOMAIN_DELETED: 'Marked for deletion',
        },
        domainsTable: {
            domain: 'Domain',
            added: 'Added',
            count: 'Links',
            status: 'Status',
            actions: 'Actions',
        },
        newDomainForm: {
            domain: 'Domain name',
            domainHint: 'Your custom domain name',
            helpText: 'You must be owner of the domain you wish to add. Added domain can be used for short link service only. Domain will require change of nameserver configuration in registrar DNS.',
        },
    },


    // Account screen
    account: {
        account: 'My Account',
        accountHint: 'View your account settings, change your name and password.',
        accountForm: {
            email: 'Email',
            name: 'Name',
            nameHint: 'Tell us your how shall we call you',
        },
        passForm: {
            current: 'Current password',
            password: 'New password',
            password2: 'Confirm new password',
        },
        buttons: {
            resend: 'Resend email',
            chpass: 'Change password',
            change: 'Change',
        },
        messages: {
            EMAIL_RESENT: 'Email sent',
            EMAIL_FAILED: 'Failed to send email',
            USER_DELETED: 'Account is marked for deletion and will be deleted in few days time.',
            PASSWORD_CHANGED: 'Password changed',
            PASSWORD_FAILED: 'Failed to change password',
        },
        emailOk: 'Email confirmed',
        emailNok: 'Email not confirmed',
        emailNokHint: 'Email not confirmed. Please check your mailbox for confirmation email.',
        delete: 'Delete Account',
        deleteHint: 'Request complete account deletion and removal of all your data.',
        confirmDelete: 'Delete your account?',
        confirmDeleteHint: 'This will delete your account, all your data and statistics. Account cannot be restored after deletion.',
        markedDeletion: 'This account is marked for deletion and will be removed in few days time. Contact support if this action needs to be cancelled. Restore is not possible as soon as account is deleted.',
        passDialog: 'Change Password',
    },


    // Plans
    plans: {
        betaTestingInfo: 'This service is in a promotion period. We will enable charges later. Until then you can use PRO plan free of charge...',
        upgradeMsg: 'Please upgarde to the next level subscription plan to unlock this functionality...',
        emailNotConfirmed: 'Your email address is not confirmed. In order to change your subscription plan you need to confirm your email address. Please check your mailbox for confirmation email or resend it from your account settings...',
        goToSettings: 'Open account settings',
        subscription: 'My Subscription',
        subscriptionHint: 'Manage your subscription plan. Press "Activate" button on subscription plan if you wish to change to it.',
        curMonth: 'EUR/month',
        noCharge: 'FREE',
        active: 'Active',
        unlimited: 'Unlimited',
        months: 'months',
        days: 'days',
        plans: {
            free: 'Free',
            basic: 'Basic',
            pro: 'Pro',
            system: 'System',
        },
        buttons: {
            activate: {
                label: 'Activate',
                hint: 'Change your subscription plan',
            },
        },
        limit_links: 'Total links:',
        limit_groups: 'Total groups:',
        limit_life: 'Link lifetime:',
        limit_history: 'Statistics history:',
        limit_logs: 'Raw logs history:',
        allow_api: 'API access:',
        allow_domains: 'Custom domains:',
        allow_keys: 'Custom link keys:',
        offer: 'If you need bigger limits, unlimited plan or enterprise support option then contact us via support email to get an individual contract proposal.',
    },


    // Support
    support: {
        support: 'Support',
        supportHint: 'Contact support using below email in case of any issues or questions. Send your email from address you have used to create your account and we will respond as soon as we can.',
        emailHint: 'Support email:',
    },


    // Qr
    qr: {
        qrDialog: 'QR Code',
        qrDialogHint: 'Create QR code for your link. Select different settings to modify the look of your QR. Click "Save File" button to save the QR image to your disk.',
        eccLevel: 'Error correction',
        ecc: {
            1: {label: 'Level L (Low)', hint: '7% of data bytes can be restored'},
            2: {label: 'Level M (Medium)', hint: '15% of data bytes can be restored'},
            3: {label: 'Level Q (Quartile)', hint: '25% of data bytes can be restored'},
            4: {label: 'Level H (High)', hint: '30% of data bytes can be restored'},
        },
        style: 'Style',
        styles: {
            1: 'Classic',
            2: 'Rounded dots',
            3: 'Rounded all',
        },
        color: 'Color',
        colors: {
            1: 'Black',
            2: 'Solid color',
            3: '2-color gradient',
            4: '3-color gradient',
        },
        buttons: {
            save: {
                label: 'Save file',
                hint: 'Save QR code to your disk as SVG file',
            },
        },
        saveSVG: 'Save as SVG file',
        savePNG1: 'Save as small PNG file',
        savePNG2: 'Save as large PNG file',
    },

}