import {es} from 'vuetify/lib/locale'

export default {
    ...es,

    errors: {
        'DEFAULT': 'Código de error {0}',
        '0': 'Error de red o conexión',
        '403': 'Acceso denegado',
        '404': 'Recurso solicitado no encontrado',
        '422': 'El formulario contiene errores',
        '500': 'Error del servidor backend',
        'UNKNOWN': 'Error desconocido',
        'NETWORK': 'Error de red o conexión',
        'BACKEND_FAILED': 'Fallo de conexión con el backend',
        'LOGIN_FAILED': 'Inicio de sesión fallido',
    },
    
    // Global messages
    messages: {
        'REQUEST_FAILED': 'Fallo en la solicitud',
        'SAVE_OK': 'Guardado',
        'SAVE_ERROR': 'Error al guardar',
        'SESSION_EXPIRED': 'Su sesión ha expirado. Por favor, inicie sesión nuevamente.',
        'CLIPBOARD_COPIED': 'Copiado al portapapeles',
        'CLIPBOARD_FAILED': 'Error al copiar',
        'CLIPBOARD_NOT_AVAIL': 'Portapapeles no disponible',
        'PLAN_LIMIT': 'Ha alcanzado el límite de su plan de suscripción',
        'PLAN_ALLOWED': 'Función no disponible en su plan de suscripción',
    },
    
    // Global buttons
    buttons: {
        ok: 'OK',
        cancel: 'Cancelar',
        close: 'Cerrar',
        delete: 'Eliminar',
        copy: {
            label: 'Copiar',
            hint: 'Copiar al portapapeles',
        },
        confirm: 'Confirmar',
        refresh: 'Actualizar',
        add: 'Agregar',
        save: {
            label: 'Guardar',
            hint: 'Guardar cambios',
        },
        help: {
            label: 'Ayuda',
            hint: 'Mostrar ayuda',
        },
        config: {
            label: 'Configurar',
            hint: 'Configurar',
        },
        translate: {
            label: 'Traducir',
            hint: 'Traducciones',
        },
        upgrade: 'Actualizar',
    },
    
    // Interface
    interface: {
        logout: 'Cerrar sesión',
        info: 'Información',
        success: 'Éxito',
        warning: 'Advertencia',
        error: 'Error',
        rowsPerPage: 'Por página:',
        allItems: 'Todos',
    },
    
    // Page titles
    titles: {
        common: 'shorten-url',
        account: 'Configuración de cuenta',
        api: 'Acceso API',
        domains: 'Mis dominios',
        group: 'Configuración de grupo',
        links: 'Mis enlaces',
        link: 'Configuración del enlace',
        logs: 'Registros de enlaces',
        plans: 'Configuración de suscripción',
        login: 'Mi cuenta',
        register: 'Crear nueva cuenta',
        forgotPass: 'Restablecer contraseña',
        stats: 'Estadísticas de enlaces',
        support: 'Soporte',
    },

    // Main menu
    menu: {
        urls: 'Enlaces cortos',
        myurls: 'Mis enlaces',
        domains: 'Mis dominios',
        settings: 'Configuración',
        account: 'Mi cuenta',
        plans: 'Mi suscripción',
        apikeys: 'Acceso API',
        help: 'Ayuda',
        support: 'Soporte',
    },
    
    // Form validation messages
    val: {
        invalid: 'Caracteres inválidos',
        required: 'Campo requerido',
        shorter: 'Debe ser más corto',
        longer: 'Debe ser más largo',
        less: 'Debe ser menos',
        more: 'Debe ser más',
        numeric: 'Debe ser numérico',
        alphanumeric: 'Solo letras y números',
        url: 'Debe ser una URL válida',
        ip: 'Debe ser una IP válida',
        domain: 'Debe ser un dominio válido',
        domainpart: 'Debe ser un nombre de dominio válido o parte de él',
        phone: 'Debe ser un número de teléfono válido',
        email: 'Debe ser una dirección de correo electrónico válida',
        linkkey: 'Solo letras, números, - y _ símbolos',
        passmatch: 'Las contraseñas no coinciden',
        agree: 'Debe aceptar los términos del servicio',
        telegram: 'Debe ser un nombre de usuario de Telegram válido',
        whatsapp: 'Debe ser un número de teléfono de Whatsapp válido',
        instagram: 'Debe ser un nombre de usuario de Instagram válido',
        linkedin: 'Debe ser un nombre de usuario de LinkedIn válido',
        youtube: 'Debe ser un ID de canal o nombre de usuario de YouTube válido',
        facebook: 'Debe ser un nombre de perfil de Facebook válido',
    },
    
    // Login screen
    login: {
        username: {
            label: 'Correo electrónico',
            hint: 'Ingrese su correo electrónico de inicio de sesión',
            errors: [
                'Ingrese su correo electrónico de inicio de sesión',
                'Debe ser un correo electrónico válido',
            ],
        },
        password: {
            label: 'Contraseña',
            hint: 'Ingrese su contraseña',
            errors: [
                'Ingrese su contraseña',
            ],
        },
        buttons: {
            login: "Iniciar sesión",
        },
        errors: [
            'Contraseña incorrecta',
            'Inicio de sesión fallido',
        ],
        remember: 'Recordarme',
        forgot: '¿Olvidó su contraseña?',
        register: 'Registrar nueva cuenta',
        user: 'Usuario',
    },

    // Register
    register: {
        regForm: {
            email: 'Correo electrónico',
            emailHint: 'El correo electrónico se utilizará para iniciar sesión',
            name: 'Tu nombre',
            nameHint: '¿Cómo quieres que te llamemos?',
            password: 'Contraseña',
            passwordHint: 'Elige tu contraseña',
            password2: 'Confirmación de contraseña',
            password2Hint: 'Confirma tu contraseña',
        },
        createNew: 'Crear nueva cuenta',
        agreeTerms: 'Aceptar los términos del servicio y la política de privacidad',
        termsHint: 'Al utilizar este servicio, aceptas los <a href="https://shorten-url.com/terms" target="_blank">Términos</a> y la <a href="https://shorten-url.com/policy" target="_blank">Política de privacidad</a>.',
        loginExisting: 'Iniciar sesión con una cuenta existente',
        messages: {
            CAPTCHA_FAILED: 'Error de validación de Captcha',
            REG_SUCCESS: 'Verifica tu correo electrónico para completar el registro...',
            REG_FAILED: 'Registro fallido',
            REG_OK: 'Verifica tu correo electrónico para completar el registro...',
        },
    },

    // Remind password
    remind: {
        remindForm: {
            email: 'Correo electrónico',
            emailHint: 'Tu correo electrónico de inicio de sesión',
        },
        messages: {
            SEND_OK: 'Por favor, revisa tu bandeja de entrada para el correo electrónico de restablecimiento de contraseña',
            SEND_FAILED: 'No se pudo enviar',
        },
        sendRemind: 'Enviar enlace de restablecimiento',
        sendRemindHint: 'Te enviaremos un enlace de restablecimiento de contraseña e instrucciones si el correo electrónico ingresado es correcto.',
        loginExisting: 'Volver a iniciar sesión',
    },

    // Reset password
    reset: {
        resetForm: {
            password: 'Contraseña',
            passwordHint: 'Elige tu nueva contraseña',
            password2: 'Confirmación de contraseña',
            password2Hint: 'Confirma tu nueva contraseña',
        },
        messages: {
            SET_OK: 'Tu contraseña se ha cambiado, úsala para iniciar sesión',
            SET_FAILED: 'No se pudo establecer la nueva contraseña',
        },
        setPass: 'Establecer nueva contraseña',
        newPassHint: 'Por favor, establece tu nueva contraseña',
        loginExisting: 'Volver a iniciar sesión',
    },

    // Confirm new user email
    confirm: {
        messages: {
            CONFIRM_OK: 'Tu correo electrónico se confirmó correctamente.',
            CONFIRM_FAILED: 'No se pudo confirmar el correo electrónico',
        },
    },

    // Groups
    groups: {
        groups: 'Grupos',
        default: 'Grupo predeterminado',
        disabled: 'El grupo está deshabilitado. Todos los enlaces en este grupo estarán inactivos hasta que habilites el grupo.',
        newGroupHint: 'Crear nuevo grupo',
        groupSettingsHint: 'Configuración del grupo',
        newGroupDialog: 'Nuevo grupo',
        newGroupDialogHint: 'Crea nuevos grupos de enlaces para gestionar mejor tus enlaces. Agrupar te proporciona un mejor control sobre un conjunto de enlaces juntos.',
        messages: {
            GROUP_ADDED: 'Grupo creado',
        },
        newGroupForm: {
            name: 'Nombre del grupo',
            nameHint: 'Asigna cualquier nombre al grupo',
        },
    },

    // Group screen
    group: {
        backHint: 'Volver a los enlaces',
        group: 'Configuración del grupo',
        groupHint: 'Administra la configuración del grupo, cambia el nombre y el estado del grupo. Si deshabilitas el grupo, todos los enlaces en este grupo también estarán deshabilitados.',
        filters: 'Filtros de tráfico',
        filtersHint: 'Filtrar visitantes no deseados por país, dirección IP o por el dispositivo y software que utiliza el visitante. Los filtros se basan en datos enviados por el software del visitante y pueden no ser precisos.',
        filterType: 'Tipo de filtro de grupo:',
        messages: {
            GROUP_DELETED: 'Grupo eliminado',
        },
        status: {
            0: 'Todos',
            1: 'Habilitado',
            2: 'Deshabilitado',
        },
        groupForm: {
            name: 'Nombre del grupo',
            nameHint: 'Nombre de tu grupo',
            color: 'Color',
            status: 'Estado del grupo',
            disabledWarn: 'Todos los enlaces en este grupo estarán inactivos si deshabilitas el grupo.',
        },
        delete: 'Eliminar',
        deleteHint: 'Eliminar este grupo, todos los enlaces en este grupo y las estadísticas relacionadas.',
        confirmDelete: '¿Eliminar grupo y sus enlaces?',
        confirmDeleteHint: 'Esto eliminará este grupo y todos los enlaces en este grupo',
    },

    // Links screen
    links: {
        links: 'Mis enlaces',
        linksHint: 'Administra tus enlaces cortos en este grupo. Presiona los botones a la derecha para ver estadísticas de clics en enlaces cortos, registro de solicitudes sin procesar, generar código QR para tu enlace. Selecciona enlaces usando la casilla de verificación a la izquierda para eliminar o desactivar el enlace o moverlo a otro grupo.',
        newLinkDialog: 'Nuevo enlace corto',
        newLinkDialogHint: 'Crea un nuevo enlace corto. Haz clic en configuración avanzada para establecer la descripción del enlace, la fecha de caducidad, la contraseña de acceso y el dominio.',
        selected: 'Seleccionado',
        newLinkForm: {
            type: 'Tipo',
            advanced: 'Configuración avanzada',
            advancedHint: 'Agrega una descripción del enlace, establece una contraseña de acceso, configura la fecha de caducidad y más...',
            domain: 'Dominio de URL corta',
            key: 'Clave de URL corta (opcional)',
            keyHint: 'Deja en blanco para obtener una clave aleatoria',
            expiry: 'Fecha de caducidad (opcional)',
            expiryHint: 'Deja en blanco para nunca',
            descr: 'Descripción (opcional)',
            descrHint: 'Agrega una breve nota para ti mismo',
            password: 'Contraseña de acceso (opcional)',
            passwordHint: 'Protege el enlace con una contraseña',
            yourNewLink: 'Tu nuevo enlace corto:',
        },
        linksTable: {
            type: 'Tipo',
            url: 'URL del enlace',
            clicks: 'Clics',
            status: 'Estado',
            actions: 'Acciones',
            qrHint: 'Crear código QR',
            statsHint: 'Ver estadísticas',
            logHint: 'Ver registro',
            search: 'Buscar',
            resetSearch: 'Limpiar parámetros de búsqueda',
            noData1: 'No hay enlaces en este grupo...',
            noData2: 'Presiona el botón "Crear nuevo..." para crear un nuevo enlace corto.',
            noResults: 'No hay enlaces que coincidan con tu búsqueda...',
            loading: 'Cargando datos...',
        },
        buttons: {
            unsetDate: "Nunca Expira",
            addmore: "Agregar Más",
            move: {
                label: 'Mover',
                hint: 'Mover enlaces seleccionados a otro grupo',
            },
            disable: {
                label: 'Desactivar',
                hint: 'Desactivar temporalmente enlaces seleccionados',
            },
            delete: {
                label: 'Eliminar',
                hint: 'Eliminar enlaces seleccionados',
            },
            new: {
                label: 'Crear Nuevo...',
                hint: 'Crear nuevo enlace corto',
            },
            search: {
                label: 'Buscar',
                hint: 'Buscar enlaces',
            },
            enable: {
                label: 'Habilitar',
                hint: 'Habilitar enlaces seleccionados',
            },
        },
        messages: {
            LINK_ADDED: 'Nuevo enlace corto creado',
            LINKS_DELETED: 'Enlaces eliminados',
            LINKS_DISABLED: 'Enlaces desactivados',
            LINKS_ENABLED: 'Enlaces habilitados',
            LINKS_MOVED: 'Enlaces movidos',
        },
        status: {
            0: 'Todos',
            1: 'Habilitado',
            2: 'Deshabilitado',
            3: 'Caducado',
            4: 'Inactivo',
            5: 'Bloqueado',
        },
        types: {
            all: {
                name: 'Todos',
            },
            web: {
                name: 'Página web',
                descr: 'Enlace a cualquier página web',
                dataName: 'URL',
                dataHint: 'Ingresa la URL de la página comenzando con http:// o https://',
            },
            phone: {
                name: 'Número de teléfono',
                descr: 'Enlace a un número de teléfono',
                dataName: 'Número de teléfono',
                dataHint: 'Ingresa el número de teléfono completo sin espacios ni guiones',
            },
            email: {
                name: 'Correo electrónico',
                descr: 'Enlace a una dirección de correo electrónico',
                dataName: 'Correo electrónico',
                dataHint: 'Ingresa la dirección de correo electrónico',
            },
            telegram: {
                name: 'Telegram',
                descr: 'Enlace a un perfil de Telegram',
                dataName: 'Nombre de usuario de Telegram',
                dataHint: 'Ingresa el nombre de usuario de Telegram',
            },
            whatsapp: {
                name: 'WhatsApp',
                descr: 'Enlace a un perfil de WhatsApp',
                dataName: 'Número de teléfono',
                dataHint: 'Ingresa el número de teléfono completo de WhatsApp sin espacios ni guiones',
            },
            instagram: {
                name: 'Instagram',
                descr: 'Enlace a un perfil de Instagram',
                dataName: 'Nombre de usuario de Instagram',
                dataHint: 'Ingresa el nombre de usuario del perfil de Instagram',
            },
            linkedin: {
                name: 'LinkedIn',
                descr: 'Enlace a un perfil de LinkedIn',
                dataName: 'Nombre de perfil',
                dataHint: 'Ingresa el nombre de perfil de LinkedIn',
            },
            youtube: {
                name: 'Youtube',
                descr: 'Enlace a un canal de Youtube',
                dataName: 'ID del canal o nombre',
                dataHint: 'Ingresa el ID del canal o el nombre de usuario de Youtube comenzando con @',
            },
            facebook: {
                name: 'Facebook',
                descr: 'Enlace a un perfil de Facebook',
                dataName: 'Nombre de perfil',
                dataHint: 'Ingresa el nombre de perfil de Facebook',
            },
        },
        confirmDelete: '¿Eliminar enlaces seleccionados?',
        confirmDeleteHint: 'Esto eliminará los enlaces cortos seleccionados y todas las estadísticas relacionadas',
        confirmDisable: '¿Desactivar enlaces seleccionados?',
        confirmDisableHint: 'Esto desactivará los enlaces cortos seleccionados sin eliminarlos',
        confirmEnable: '¿Habilitar enlaces seleccionados?',
        confirmEnableHint: 'Esto habilitará los enlaces cortos seleccionados',
        moveDialog: 'Mover enlaces',
        moveDialogHint: 'Mover enlaces seleccionados a otro grupo.',
        moveForm: {
            name: 'Nombre del grupo',
            nameHint: 'Asigna cualquier nombre al grupo',
            newGroup: 'Crear nuevo grupo...',
        },
    },

    // Link screen
    link: {
        backHint: 'Volver a los enlaces',
        link: 'Configuración del enlace',
        linkHint: 'Administra datos y configuraciones del enlace.',
        filters: 'Filtros de tráfico',
        filtersHint: 'Filtrar visitantes no deseados por país, dirección IP o por el dispositivo y software que utiliza el visitante. Los filtros se basan en datos enviados por el software del visitante y pueden no ser precisos.',
        filterType: 'Tipo de filtro:',
        deactivated: 'El enlace está desactivado',
        blocked: 'El enlace está bloqueado',
        linkForm: {
            created: 'Enlace creado:',
            clicks: 'Clics totales:',
            clicks_ok: 'Aprobados:',
            clicks_blocked: 'Bloqueados:',
            type: 'Tipo:',
            descr: 'Descripción (opcional)',
            descrHint: 'Agrega una breve nota para ti mismo',
            group: 'Grupo',
            status: 'Estado',
            expiry: 'Fecha de caducidad (opcional)',
            expiryHint: 'Deja en blanco para nunca',
            password: 'Contraseña de acceso (opcional)',
            passwordHint: 'Protege el enlace con una contraseña',
        },
        buttons: {
            unsetDate: "Nunca Expira",
        },
        messages: {
            LINK_DELETED: 'Enlace corto eliminado',
        },
        delete: 'Eliminar',
        deleteHint: 'Eliminar este enlace y todas las estadísticas recopiladas.',
        confirmDelete: '¿Eliminar enlace corto?',
        confirmDeleteHint: 'Esto eliminará tu enlace corto y todas las estadísticas relacionadas',
    },


    // Filters
    filters: {
        types: {
            all: {
                name: 'Todos',
            },
            source: {
                name: 'Fuente de tráfico',
                descr: 'Bloquear bots, tráfico directo y enlaces desde sitios web',
                allow_bots: 'Permitir bots de motores de búsqueda',
                allow_direct: 'Permitir tráfico directo',
                allow_sites: 'Permitir clics desde otros sitios web',
                filterSites: 'Filtrar sitios web de referencia:',
                addSite: 'Agregar sitio web',
                wldescr: 'bloquear todos los dominios excepto los enumerados a continuación',
                bldescr: 'permitir todos los dominios excepto los enumerados a continuación',
                domains: {
                    0: '',
                    1: 'Dominios de sitios web permitidos:',
                    2: 'Dominios de sitios web bloqueados:',
                },
                addDialog: {
                    title: 'Agregar sitio web',
                    hint: 'Agregar nuevo dominio de sitio web a la lista',
                    addButton: 'Agregar',
                    domain: 'Dominio del sitio web',
                    helpText: 'Ingresa el nombre de dominio completo de un sitio web o cualquier parte de él. Por ejemplo: "google.com", "google", ".com" ... Si ingresas solo una parte del dominio, afectará a todos los dominios que tengan esta parte. Por ejemplo: ".com" significará todos los dominios en la zona .com.',
                },
                noSelectedWarn: 'Agrega sitios web permitidos a la lista, de lo contrario, se bloquearán todas las solicitudes.',
            },
            country: {
                name: 'Países',
                descr: 'Bloquear países seleccionados',
                wldescr: 'bloquear todos los países excepto los seleccionados',
                bldescr: 'permitir todos los países excepto los seleccionados',
                countries: {
                    0: 'Países',
                    1: 'Países permitidos:',
                    2: 'Países bloqueados:',
                },
                noSelectedWarn: 'Agrega países permitidos a la lista, de lo contrario, se bloquearán todas las solicitudes.',
            },
            ip: {
                name: 'Dirección IP',
                descr: 'Bloquear rangos de direcciones IP seleccionados',
                wldescr: 'bloquear todas las direcciones IP excepto las enumeradas a continuación',
                bldescr: 'permitir todas las direcciones IP excepto las enumeradas a continuación',
                addresses: {
                    0: '',
                    1: 'Direcciones IP permitidas:',
                    2: 'Direcciones IP bloqueadas:',
                },
                addIP: 'Agregar dirección IP',
                addDialog: {
                    title: 'Agregar dirección IP',
                    hint: 'Agregar nueva dirección IP o rango a la lista',
                    addButton: 'Agregar',
                    single: 'Dirección IP única',
                    range: 'Rango de direcciones IP',
                    from: 'Inicio del rango',
                    to: 'Fin del rango',
                    ip: 'Dirección IP',
                    mustBeLarger: 'Debe ser mayor que el inicio del rango',
                },
                noSelectedWarn: 'Agrega direcciones IP permitidas a la lista, de lo contrario, se bloquearán todas las solicitudes.',
            },
            device: {
                name: 'Tipo de dispositivo',
                descr: 'Bloquear tipos de dispositivos seleccionados',
                allowed: 'Dispositivos permitidos:',
                allow_desktop: 'Computadoras de escritorio y portátiles',
                allow_mobile: 'Dispositivos móviles',
                allow_tv: 'TV, multimedia para autos, consolas',
                allow_wear: 'Dispositivos ponibles y periféricos',
                allow_other: 'Otros y desconocidos',
            },
            software: {
                name: 'Software del dispositivo',
                descr: 'Bloquear sistemas operativos y navegadores',
            },
        },
        action: 'Acción:',
        actions: {
            0: 'No filtrar',
            1: 'Lista blanca',
            2: 'Lista negra',
        },
    },

    // Rules
    rules: {
        rules: 'Reglas de redirección',
        rulesHint: 'Crear destinos de enlace alternativos según el idioma del navegador del visitante, el país del visitante y el software del navegador.',
        noRulesHint: 'Presiona el botón "Nueva regla" para crear tu primera regla...',
        buttons: {
            add: {
                label: 'Nueva Regla',
                hint: 'Crear nueva regla de redirección',
            },
        },
        ruleDialog: {
            titleAdd: 'Agregar nueva regla',
            hintAdd: 'Agregar nueva regla a la lista',
            titleEdit: 'Cambiar regla',
            hintEdit: 'Cambiar configuración de regla',
            priority: 'Prioridad',
        },
        ruleForm: {
            type: 'Tipo',
            priority: 'Prioridad de la regla: ',
            if: 'Si...',
            select: 'Seleccionar:',
        },
        rulesTable: {
            priority: 'P-ty',
            if: 'Si...',
            to: 'Redirigir a',
            actions: 'Acciones',
            never: 'Nunca',
        },
        confirmDel: '¿Eliminar regla?',
        redirectto: 'Redirigir a:',
        types: {
            all: {
                name: 'Todos',
            },
            lang: {
                name: 'Idioma del navegador',
            },
            country: {
                name: 'País del visitante',
            },
            device: {
                name: 'Fuente de tráfico',
            },
            os: {
                name: 'Sistema operativo',
            },
            browser: {
                name: 'Navegador',
            },
        },
        devices: {
            bot: 'Bots de motores de búsqueda',
            desktop: 'Computadoras de escritorio y portátiles',
            mobile: 'Dispositivos móviles',
            tv: 'TV, multimedia para autos, consolas',
            wear: 'Dispositivos ponibles y periféricos',
        },
    },

    // Link stats screen
    stats: {
        backHint: 'Volver a los enlaces',
        linkSettings: 'Configuración del enlace',
        linkLogs: 'Registros en bruto del enlace',
        stats: 'Estadísticas del enlace',
        statsHint: 'Ver estadísticas de clics en el enlace. Selecciona un período para el cual deseas ver las estadísticas haciendo clic en los presets a continuación.',
        noStatsHint: 'Nada para este período',
        statsFor: 'Mostrar estadísticas para:',
        periods: {
            today: 'Hoy',
            yesterday: 'Ayer',
            day: 'Fecha...',
            thisMonth: 'Este mes',
            prevMonth: 'Mes anterior',
            month: 'Mes...',
            selectDates: 'Seleccionar fechas...',
        },
        sections: {
            clicks: 'Clics',
            clicksHint: 'Observa cuántos clics tienes, cuántos de esos clics fueron aprobados y bloqueados debido a filtros o contraseña. Observa las fuentes de tráfico desde las cuales provienen tus usuarios.',
            sources: 'Fuentes de tráfico:',
            // sourcesHint: 'This chart shows where your link visitors come from, links from other websites, qr code or directly typed address.',
            results: 'Aprobados / Bloqueados:',
            devices: 'Dispositivos y software',
            devicesHint: 'Observa qué dispositivos y software están utilizando tus visitantes.',
            deviceTypes: 'Tipos de dispositivos:',
            os: 'Sistemas operativos:',
            browsers: 'Navegadores:',
            clientTypes: 'Tipos de clientes:',
            searchBots: 'Bots de búsqueda:',
            clientLangs: 'Idiomas del navegador:',
            geo: 'Geografía',
            geoHint: 'Geografía de clics. Observa desde qué países provienen tus usuarios. Esta información puede ser inexacta si se utiliza un servicio de VPN.',
            showBots: 'Mostrar bots de búsqueda',
        },
        charts: {
            1: {
                clicks: 'Clics:',
                clicks_success: 'Aprobados:',
                clicks_blocked: 'Bloqueados:',
                success: 'Aprobados',
                blocked: 'Bloqueados',
            },
            2: {
                success: 'aprobados',
                blocked: 'bloqueados',
            },
        },
    },


    // Logs screen
    logs: {
        backHint: 'Volver a los enlaces',
        linkSettings: 'Configuración del enlace',
        linkStats: 'Estadísticas del enlace',
        logs: 'Registros del enlace',
        logsHint: 'Ver registros en bruto del enlace. Selecciona un período para el cual deseas ver los registros haciendo clic en los presets a continuación.',
        logsFor: 'Mostrar registros para:',
        logDialog: 'Registro completo',
        periods: {
            today: 'Hoy',
            yesterday: 'Ayer',
            day: 'Fecha...',
        },
        buttons: {
            search: {
                label: 'Buscar',
                hint: 'Buscar entradas de registro',
            },
            view: {
                label: 'Ver',
                hint: 'Ver entrada de registro completa',
            },
        },
        logsTable: {
            when: 'Cuándo',
            ip: 'IP',
            country: 'País',
            locale: 'Configuración regional',
            source: 'Fuente',
            device: 'Dispositivo',
            os: 'Sistema operativo',
            browser: 'Navegador',
            result: 'Resultado',
            actions: 'Acciones',
        },
        devices: {
            0: 'Desconocido',
            1: 'Bot de búsqueda',
            2: 'Computadora/Portátil',
            3: 'Teléfono inteligente',
            4: 'Tableta',
            5: 'Teléfono básico',
            6: 'Consola',
            7: 'TV',
            8: 'Navegador de automóvil',
            9: 'Pantalla inteligente',
            10: 'Cámara',
            11: 'Reproductor multimedia',
            12: 'Phablet',
            13: 'Altavoz inteligente',
            14: 'Dispositivo ponible',
            15: 'Periférico',
        },
        clients: {
            0: 'Desconocido',
            1: 'Bot de búsqueda',
            2: 'Navegador',
            3: 'Lector',
            4: 'Biblioteca',
            5: 'Reproductor multimedia',
            6: 'Aplicación móvil',
            7: 'Mensajero',
        },
        results: {
            1: 'Aprobado',
            2: 'Sin contraseña',
            3: 'Contraseña incorrecta',
            11: 'Filtro de país',
            12: 'Filtro de tráfico',
            13: 'Filtro de dispositivo',
            14: 'Filtro de IP',
        },
        sources: {
            bot: 'Bot de búsqueda',
            direct: 'Tráfico directo',
            qr: 'Código QR',
        },
        tables: {
            when: 'Cuándo:',
            source: 'Fuente:',
            destination: 'Redirigido a:',
            blocked: 'Bloqueado: ',
            device: {
                label: 'Dispositivo',
                type: 'Tipo de dispositivo:',
                brand: 'Marca del dispositivo:',
                model: 'Modelo del dispositivo:',
            },
            os: {
                label: 'Sistema operativo',
                name: 'Nombre:',
                version: 'Versión:',
            },
            geo: {
                label: 'IP Geográfico',
                ip: 'Dirección IP:',
                country: 'Código del país:',
                coords: 'Coordenadas:',
            },
            soft: {
                label: 'Software',
                clientType: 'Tipo de cliente:',
                locale: 'Configuración regional del idioma:',
                browserName: 'Nombre del navegador:',
                browserVer: 'Versión del navegador:',
                clientName: 'Nombre del cliente:',
                clientVer: 'Versión del cliente:',
            },
            bot: {
                label: 'Bot de búsqueda',
                name: 'Nombre del bot:',
            },
        },
    },

    // API keys screen
    apikeys: {
        apikeys: 'Claves API',
        apikeysHint: 'Administra tus claves API. Se requiere una clave API para acceder a tu cuenta mediante la API. El acceso a la API te permite gestionar tus enlaces y grupos de forma programática. Cada clave API también puede estar restringida a acceso solo de lectura. Guarda tus claves en un lugar seguro.',
        noKeys1: 'Se requieren claves API para acceder a tu cuenta a través de la API.',
        noKeys2: 'Presiona el botón "Crear nueva..." para crear tu primera clave...',
        neverExpires: 'Nunca expira',
        newKeyDialog: 'Crear nueva clave',
        newKeyDialogHint: 'Crear nueva clave API.',
        showKeyDialog: 'Ver clave',
        showKeyDialogHint: 'Aquí puedes ver la clave API y copiarla al portapapeles. Guárdala en un lugar seguro.',
        confirmDel: '¿Eliminar clave API?',
        viewDoc: 'Ver documentación API',
        status: {
            0: 'Todos',
            1: 'Habilitada',
            2: 'Deshabilitada',
            3: 'Caducada',
        },
        buttons: {
            new: {
                label: 'Crear nueva...',
                hint: 'Crear nueva clave API',
            },
            view: {
                label: 'Ver clave',
                hint: 'Ver clave API',
            },
        },
        messages: {
            KEY_ADDED: 'Clave API creada',
            KEY_DELETED: 'Clave API eliminada',
        },
        keysTable: {
            name: 'Nombre de la clave',
            created: 'Creada',
            permission: 'Permiso',
            expires: 'Caduca',
            status: 'Estado',
            actions: 'Acciones',
            fullPerm: 'Completa',
            roPerm: 'Solo lectura',
        },
        newKeyForm: {
            name: 'Nombre de la clave',
            nameHint: 'Dale un nombre a la nueva clave',
            readonly: 'Permiso solo lectura',
            expiry: 'Fecha de caducidad',
            expiryHint: 'Dejar en blanco para nunca',
        },
    },


    // Domains screen
    domains: {
        domains: 'Mis Dominios',
        domainsHint: 'Puedes usar tus propios dominios personalizados para tus enlaces cortos. Con un dominio personalizado, obtienes un control total sobre las URL y claves de tus enlaces, mejorando tu estrategia de marketing de marca. Debes ser el propietario del dominio personalizado que deseas agregar. Se requerirán cambios en la configuración del servidor de nombres para apuntar tu dominio a nuestros servidores.',
        noDomains1: 'Puedes agregar tus propios dominios personalizados para tus enlaces cortos. ',
        noDomains2: 'El dominio personalizado estará disponible solo para ti y proporcionará control exclusivo sobre las claves de enlace corto. Podrás crear tus enlaces cortos como https://tu_dominio/tu_clave',
        noDomains3: 'Debes ser el propietario del dominio que deseas utilizar y el dominio no debe estar en uso en otros sitios web. Deberás configurar tu dominio para que apunte a nuestros servidores de nombres para comenzar.',
        noDomains5: 'Presiona el botón "Agregar Dominio" para comenzar a agregar tu dominio personalizado...',
        newDomainDialog: 'Agregar Tu Dominio',
        newDomainDialogHint: 'Agrega tu nuevo dominio personalizado.',
        confirmDel: '¿Solicitar la eliminación del dominio y todos sus enlaces?',
        nsHint: 'Para continuar con la adición del nuevo dominio, configura los siguientes servidores de nombres en el DNS de tu registrador de dominios:',
        status: {
            0: 'Todos',
            1: 'Habilitado',
            2: 'Deshabilitado',
            3: 'Procesando',
            4: 'En eliminación',
        },
        buttons: {
            new: {
                label: 'Agregar Dominio',
                hint: 'Agregar tu dominio personalizado',
            },
        },
        messages: {
            DOMAIN_ADDED: 'Dominio agregado',
            DOMAIN_DELETED: 'Marcado para eliminación',
        },
        domainsTable: {
            domain: 'Dominio',
            added: 'Agregado',
            count: 'Enlaces',
            status: 'Estado',
            actions: 'Acciones',
        },
        newDomainForm: {
            domain: 'Nombre de dominio',
            domainHint: 'Tu nombre de dominio personalizado',
            helpText: 'Debes ser el propietario del dominio que deseas agregar. El dominio agregado se puede utilizar solo para el servicio de enlaces cortos. El dominio requerirá un cambio en la configuración del servidor de nombres en el DNS del registrador.',
        },
    },

    // Pantalla de cuenta
    account: {
        account: 'Mi Cuenta',
        accountHint: 'Consulta la configuración de tu cuenta, cambia tu nombre y contraseña.',
        accountForm: {
            email: 'Correo electrónico',
            name: 'Nombre',
            nameHint: 'Dinos cómo debemos llamarte',
        },
        passForm: {
            current: 'Contraseña actual',
            password: 'Nueva contraseña',
            password2: 'Confirmar nueva contraseña',
        },
        buttons: {
            resend: 'Reenviar correo electrónico',
            chpass: 'Cambiar contraseña',
            change: 'Cambiar',
        },
        messages: {
            EMAIL_RESENT: 'Correo electrónico enviado',
            EMAIL_FAILED: 'Error al enviar el correo electrónico',
            USER_DELETED: 'La cuenta está marcada para eliminación y se eliminará en unos días.',
            PASSWORD_CHANGED: 'Contraseña cambiada',
            PASSWORD_FAILED: 'Error al cambiar la contraseña',
        },
        emailOk: 'Correo electrónico confirmado',
        emailNok: 'Correo electrónico no confirmado',
        emailNokHint: 'Correo electrónico no confirmado. Por favor, revisa tu bandeja de entrada para encontrar el correo electrónico de confirmación.',
        delete: 'Eliminar cuenta',
        deleteHint: 'Solicita la eliminación completa de la cuenta y la eliminación de todos tus datos.',
        confirmDelete: '¿Eliminar tu cuenta?',
        confirmDeleteHint: 'Esto eliminará tu cuenta, todos tus datos y estadísticas. La cuenta no se puede restaurar después de la eliminación.',
        markedDeletion: 'Esta cuenta está marcada para eliminación y se eliminará en unos días. Contacta con el soporte si es necesario cancelar esta acción. No es posible restaurar la cuenta una vez eliminada.',
        passDialog: 'Cambiar Contraseña',
    },


    // Plans
    plans: {
        betaTestingInfo: 'Este servicio se encuentra en periodo de promoción. Habilitaremos los cargos más adelante. Mientras tanto, puedes usar el plan PRO de forma gratuita...',
        upgradeMsg: 'Por favor, actualiza a la siguiente suscripción para desbloquear esta funcionalidad...',
        emailNotConfirmed: 'Tu dirección de correo electrónico no está confirmada. Para cambiar tu plan de suscripción, debes confirmar tu dirección de correo electrónico. Por favor, revisa tu bandeja de entrada para encontrar el correo electrónico de confirmación o reenvíalo desde la configuración de tu cuenta...',
        goToSettings: 'Abrir configuración de cuenta',
        subscription: 'Mi Suscripción',
        subscriptionHint: 'Administra tu plan de suscripción. Presiona el botón "Activar" en el plan de suscripción si deseas cambiar a él.',
        curMonth: 'EUR/mes',
        noCharge: 'GRATIS',
        active: 'Activo',
        unlimited: 'Ilimitado',
        months: 'meses',
        days: 'días',
        plans: {
            free: 'Gratis',
            basic: 'Básico',
            pro: 'Pro',
            system: 'Sistema',
        },
        buttons: {
            activate: {
                label: 'Activar',
                hint: 'Cambiar tu plan de suscripción',
            },
        },
        limit_links: 'Enlaces totales:',
        limit_groups: 'Grupos totales:',
        limit_life: 'Vida del enlace:',
        limit_history: 'Historial de estadísticas:',
        limit_logs: 'Historial de registros sin procesar:',
        allow_api: 'Acceso a la API:',
        allow_domains: 'Dominios personalizados:',
        allow_keys: 'Claves de enlace personalizadas:',
        offer: 'Si necesita límites mayores, un plan ilimitado o una opción de soporte empresarial, contáctenos por correo electrónico de soporte para obtener una propuesta de contrato individual.',
    },


    // Soporte
    support: {
        support: 'Soporte',
        supportHint: 'Contacta con soporte utilizando el correo electrónico a continuación en caso de cualquier problema o pregunta. Envía tu correo electrónico desde la dirección que has utilizado para crear tu cuenta y responderemos tan pronto como sea posible.',
        emailHint: 'Correo electrónico de soporte:',
    },


    // Código QR
    qr: {
        qrDialog: 'Código QR',
        qrDialogHint: 'Crea un código QR para tu enlace. Selecciona diferentes configuraciones para modificar la apariencia de tu código QR. Haz clic en el botón "Guardar archivo" para guardar la imagen del código QR en tu disco.',
        eccLevel: 'Corrección de errores',
        ecc: {
            1: {label: 'Nivel L (Bajo)', hint: 'El 7% de los bytes de datos se pueden restaurar'},
            2: {label: 'Nivel M (Medio)', hint: 'El 15% de los bytes de datos se pueden restaurar'},
            3: {label: 'Nivel Q (Cuarto)', hint: 'El 25% de los bytes de datos se pueden restaurar'},
            4: {label: 'Nivel H (Alto)', hint: 'El 30% de los bytes de datos se pueden restaurar'},
        },
        style: 'Estilo',
        styles: {
            1: 'Clásico',
            2: 'Puntos redondeados',
            3: 'Todo redondeado',
        },
        color: 'Color',
        colors: {
            1: 'Negro',
            2: 'Color sólido',
            3: 'Degradado de 2 colores',
            4: 'Degradado de 3 colores',
        },
        buttons: {
            save: {
                label: 'Guardar archivo',
                hint: 'Guardar código QR en tu disco como archivo SVG',
            },
        },
        saveSVG: 'Archivo SVG',
        savePNG1: 'Pequeño archivo PNG',
        savePNG2: 'Archivo PNG grande',
    },

}